import { Injectable } from '@angular/core';
import {ApiService} from "./api.service";
import {Observable} from "rxjs";

@Injectable({
  providedIn: 'root'
})
export class OrderService {

  constructor(private api: ApiService) { }

  public getOrders()
  {
    return this.api.get("api/order");
  }

  public searchOrders(search: any = null, paginator: any = null, sort: any = null): Observable<any>
  {
    let params = {
      gridSearchFilter: search,
      gridCurrentPage: paginator?.pageIndex,
      gridItemPerPage: paginator?.pageSize,
      gridSortField: sort?.active || '',
      gridSortDirection: sort?.direction || '',
    };
    return this.api.get("api/order/search", undefined, {
      params: params
    });
  }

}
