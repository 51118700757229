import {Component, OnDestroy, ViewChild} from '@angular/core';
import {MatButton, MatMiniFabButton} from "@angular/material/button";
import {MatRow, MatRowDef, MatTable, MatTableDataSource, MatTableModule} from "@angular/material/table";
import {MatIcon} from "@angular/material/icon";
import {MatPaginator} from "@angular/material/paginator";
import {MatProgressSpinner} from "@angular/material/progress-spinner";
import {debounceTime, Subject, takeUntil} from "rxjs";
import {Order} from "../../../models/Order";
import {OrderService} from "../../../services/order.service";
import {MatDialog} from "@angular/material/dialog";
import {OrderViewComponent} from "../order-view/order-view.component";
import {MatFormField, MatLabel} from "@angular/material/form-field";
import {MatInput} from "@angular/material/input";
import {FormControl, ReactiveFormsModule} from "@angular/forms";
import {formatDate} from "../../util/date";
import {environment} from "../../../environments/environment";
import {Product} from "../../../models/Product";
import {MatSort} from "@angular/material/sort";

@Component({
  selector: 'app-orders-list-view',
  standalone: true,
  imports: [
    MatButton,
    MatTableModule,
    MatLabel,
    MatIcon,
    MatMiniFabButton,
    MatPaginator,
    MatProgressSpinner,
    MatRow,
    MatRowDef,
    MatTable,
    MatFormField,
    MatInput,
    ReactiveFormsModule
  ],
  templateUrl: './orders-list-view.component.html',
  styleUrl: './orders-list-view.component.scss'
})
export class OrdersListViewComponent implements OnDestroy {
  private unsubscribeAll: Subject<any> = new Subject();
  protected readonly formatDate = formatDate;

  protected ordersData: Order[];
  protected ordersDataSource: MatTableDataSource<Order> = new MatTableDataSource<Order>([{reference: ""}]);
  displayedColumns: string[] = ["loader"];
  @ViewChild(MatPaginator, {static: true}) paginator!: MatPaginator;
  @ViewChild(MatSort, {static: true}) sort!: MatSort;
  searchCtl = new FormControl<string>("");

  protected gridPageSizeArray = environment.gridPageSizeArray || [1, 5, 10, 25, 50];
  protected gridDefaultPageIndex = environment.gridDefaultPageIndex || 0;
  protected gridDefaultPageSize = environment.gridDefaultPageSize || 10;

  protected isLoading = false;

  constructor(private orderService: OrderService, private dialog: MatDialog) {
    // Grid preload :
    this.ordersData = [];
    this.ordersDataSource = new MatTableDataSource(this.ordersData);
  }

  ngAfterViewInit(){
    if(this.paginator === undefined){
      console.error("Houston, we’ve had a problem");
      // PhpStorm indique "Condition is always false sinces types 'MatPaginator' and 'undefined' have no overlap
      // ... mais ca peux quand meme se produire à l'execution du javascript dans le navigateur.
    }
    this.ordersDataSource.paginator = this.paginator;

    this.paginator.page.pipe(takeUntil(this.unsubscribeAll)).subscribe(    {
      next: () => {
        //console.log('rechargement suite action utilisateur sur boutons pages [<] [>] ou la liste Items per page');
        this.gridLoad();
      }
    })

    //this.sort.sortChange.pipe(takeUntil(this.unsubscribeAll)).subscribe(    {
    //  next: () => {
    //    this.gridLoad(false);
    //  }
    //})

    this.searchCtl.valueChanges.pipe(
      debounceTime(1000)
    ).pipe(takeUntil(this.unsubscribeAll)).subscribe(    {
      next: () => {
        this.gridLoad(false);
      }
    })

    this.gridLoad(); //necessaire pour chargement du nombre total de pages et resultats
  }

  ngOnDestroy(): void {
    this.unsubscribeAll.next(null)
    this.unsubscribeAll.complete()
  }

  viewOrder(order: Order) {
    this.dialog.open(OrderViewComponent, {data: {order: order}})
  }

  computeTotal(order: Order) {
    return order.product_orders?.reduce((acc, curr) => {
      return acc + (parseFloat(curr.price) + parseFloat(curr.tax)) * curr.quantity;
    }, 0).toFixed(2);
  }



  private gridLoad(keepPageIndex=true) {
    console.log("EXEC gridLoad()");

    this.loaderShow();
    if (!this.paginator.pageSize) {
      this.paginator.pageSize = this.gridDefaultPageSize
    }
    if(!keepPageIndex){
      this.paginator.pageIndex = 0;
    }

    let search = this.searchCtl.value;
    if(search === undefined){
      search = "";
    }

    this.orderService.searchOrders(search, this.paginator, this.sort)
      .pipe(takeUntil(this.unsubscribeAll))
      .subscribe({
        next: (res: any) => {
          //console.log("searchProducts subscribe => res :", res);
          this.paginator.length = res.metadata.total
          this.ordersData = res.datas;
          this.ordersDataSource = new MatTableDataSource(this.ordersData);
          this.loaderHide();
        }
        //,error: err => {
        //  this.errorService.showError(err);
        //}
      });
  }

  public loaderShow(){
    this.isLoading = true;
    this.displayedColumns = ["loader"];
    this.ordersDataSource  = new MatTableDataSource<Product>([{reference: ""}]);
  }

  public loaderHide(){
    this.isLoading = false;
    this.displayedColumns = ["id", "reference", "platform", "created_at", "products", "price",  "origin", "actions"];
  }


}
