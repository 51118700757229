import {Component, OnDestroy} from '@angular/core';
import {MatTableModule} from "@angular/material/table";
import {User} from "../../../models/User";
import {AlertService} from "../../../services/alert.service";
import {UserService} from "../../../services/user.service";
import {Subject, takeUntil} from "rxjs";
import {MatButton, MatMiniFabButton} from "@angular/material/button";
import {MatIcon} from "@angular/material/icon";
import {MatDialog} from "@angular/material/dialog";
import {EditUserFormComponent} from "../../user/edit-user-form/edit-user-form.component";
import {NgIf} from "@angular/common";
import {ConfirmPopupComponent} from "../../confirm-popup/confirm-popup.component";
import {AddUserFormComponent} from "../../user/add-user-form/add-user-form.component";
import {MatProgressSpinner} from "@angular/material/progress-spinner";

@Component({
  selector: 'app-users-list-view',
  standalone: true,
  imports: [
    MatTableModule,
    MatMiniFabButton,
    MatIcon,
    NgIf,
    MatButton,
    MatProgressSpinner
  ],
  templateUrl: './users-list-view.component.html',
  styleUrl: './users-list-view.component.scss'
})
export class UsersListViewComponent implements OnDestroy {
  private unsubscribeAll: Subject<any> = new Subject();
  usersDataSource: User[] = [{email: ""}];
  displayedColumns = ["loader"];
  private promoteChange: number[] = [];

  constructor(private alertService: AlertService, protected userService: UserService, private dialog: MatDialog) {
    this.userService.requireAdmin();
    this.userService.getUsers()
      .pipe(takeUntil(this.unsubscribeAll))
      .subscribe({
        next: users => {
          this.usersDataSource = users as User[];
          this.displayedColumns = ["lastName", "firstName", "email", "grade", "actions"]
        }
      })
  }

  ngOnDestroy(): void {
    this.unsubscribeAll.next(null);
    this.unsubscribeAll.complete();
  }

  editUser(user: User) {
    this.dialog.open(EditUserFormComponent, {data: {user: user}}).afterClosed()
      .pipe(takeUntil(this.unsubscribeAll))
      .subscribe({
        next: (userModified: User) => {
          if (userModified) {
            this.usersDataSource = this.usersDataSource.map((u: User) => u.id == user.id ? userModified : u);
          }
        }
      })
  }

  promoteUser(id: number) {
    this.promoteChange.push(id)
    this.userService.promote(id)
      .pipe(takeUntil(this.unsubscribeAll))
      .subscribe({
        next: (userModified : any) => {
          this.usersDataSource = this.usersDataSource.map((u: User) => u.id == id ? userModified : u) as User[];
          this.promoteChange = this.promoteChange.filter(i => i !== id)
          this.alertService.success("Utilisateur promu avec succès !")
        }
      })
  }

  demoteUser(id: number) {
    this.promoteChange.push(id)
    this.userService.demote(id)
      .pipe(takeUntil(this.unsubscribeAll))
      .subscribe({
        next: (userModified : any) => {
          this.usersDataSource = this.usersDataSource.map((u: User) => u.id == id ? userModified : u) as User[];
          this.promoteChange = this.promoteChange.filter(i => i !== id)
          this.alertService.success("Utilisateur rétrogradé avec succès !")
        }
      })
  }

  deleteUser(id: number, user: User) {
    this.dialog.open(ConfirmPopupComponent, {
      data: {
        title: 'Suppression de l\'utilisateur \'' + user.firstName + ' ' + user.lastName + '\'',
        question: 'Voulez-vous vraiment supprimer cet utilisateur ?'
      }
    }).afterClosed()
      .pipe(takeUntil(this.unsubscribeAll))
      .subscribe({
        next: confirm => {
          if(confirm)
          {
            this.userService.delete(id)
              .pipe(takeUntil(this.unsubscribeAll))
              .subscribe({
                next: (users) => {
                  this.usersDataSource = users as User[];
                }
              })
          }
        }
      })
  }

  isRoleChange(id: number) {
    return this.promoteChange.includes(id)
  }

  addUser() {
    this.dialog.open(AddUserFormComponent, {data: {}}).afterClosed()
      .pipe(takeUntil(this.unsubscribeAll))
      .subscribe({
        next: (user: User) => {
          if(user) {
            this.usersDataSource = [...this.usersDataSource, user]
          }
        }
      })
  }
}
