<h2 class="text-3xl uppercase text-blue-900 my-4 pb-1 font-bold relative">
  <div class="border-b-red-700 border-l-red-700 h-full w-10 border-l-[.3rem] border-b-[.3rem] absolute"></div>
  <span class="ml-[0.6rem]">Liste des commandes</span>
</h2>
<mat-form-field class="w-full">
  <mat-label>Rechercher une référence...</mat-label>
  <input matInput placeholder="..." [formControl]="searchCtl">
</mat-form-field>
<table mat-table [dataSource]="ordersDataSource">
  <ng-container matColumnDef="id">
    <th mat-header-cell *matHeaderCellDef class="">ID</th>
    <td mat-cell *matCellDef="let order"  class=""> {{ order.id }}</td>
  </ng-container>
  <ng-container matColumnDef="reference">
    <th mat-header-cell *matHeaderCellDef class="">Référence</th>
    <td mat-cell *matCellDef="let order"  class=""> {{ order.reference }}</td>
  </ng-container>
  <ng-container matColumnDef="platform">
    <th mat-header-cell *matHeaderCellDef class="">Plateforme</th>
    <td mat-cell *matCellDef="let order"  class=""> {{ order.store.name }} ({{order.store.country}})</td>
  </ng-container>
  <ng-container matColumnDef="created_at">
    <th mat-header-cell *matHeaderCellDef class="">Créée le</th>
    <td mat-cell *matCellDef="let order"  class=""> {{ formatDate(order.created_at) }}</td>
  </ng-container>
  <ng-container matColumnDef="products">
    <th mat-header-cell *matHeaderCellDef class="">Lignes</th>
    <td mat-cell *matCellDef="let order"  class=""> {{ order.product_orders.length }}</td>
  </ng-container>
  <ng-container matColumnDef="price">
    <th mat-header-cell *matHeaderCellDef class="">Prix total</th>
    <td mat-cell *matCellDef="let order"  class=""> {{ computeTotal(order) }}</td>
  </ng-container>
  <ng-container matColumnDef="origin">
    <th mat-header-cell *matHeaderCellDef class="">Origine</th>
    <td mat-cell *matCellDef="let order"  class=""> {{ order.origin }}</td>
  </ng-container>
  <ng-container matColumnDef="loader">
    <th mat-header-cell *matHeaderCellDef></th>
    <td mat-cell *matCellDef="let order">
      <div class="flex p-4 h-[50vh] items-center justify-center">
        <mat-spinner></mat-spinner>
      </div>
    </td>
  </ng-container>
  <ng-container matColumnDef="actions">
    <th mat-header-cell class="" *matHeaderCellDef></th>
    <td mat-cell class="" *matCellDef="let order">
      <div class="flex gap-2">
        <button mat-mini-fab color="accent" (click)="viewOrder(order)"><mat-icon>visibility</mat-icon></button>
      </div>
    </td>
  </ng-container>

  <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
  <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
</table>

<mat-paginator
  length="totalData"
  [pageIndex]="gridDefaultPageIndex"
  [pageSize]="gridDefaultPageSize"
  [pageSizeOptions]="gridPageSizeArray">
  showFirstLastButtons
  ></mat-paginator>
